.form-table-date-picker {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;

    @media screen and (max-width: 991px) {
        &--mobile {
            display: flex;
            flex-direction: column;
            border: 0px solid coral;
            background-color: #FFF;
            padding: 2px 0px;
            padding-top:3px;
        }

        &__label {
            font-family: 'Roboto', sans-serif;
            font-size: 13px;
            color: #798086;
            margin-bottom: 0px;
            padding-bottom: 1px;
            padding-left: 10px;

            &__required {
                color: #D51B15;
            }
        }
    }

    &__input {
        border: 1px solid #FFFFFF;
        border-bottom: 1px solid #EFEFEF;
        height: 38px;
        background-color: #FFFFFF;
        text-indent: 10px;
        border-radius: 0px;
        font-size: 14px;
        width: 100%;
 

        &:focus{
            outline: 0px;
            border: 1px solid #3084B7;
        }

        &--error {
            border: 1px solid #D51B15;
        }

        @media screen and (max-width: 991px) {
            &--mobile {
                height: 23px;
                text-align: left;
                border-bottom: 1px solid #FFF;
                padding: 2px 0;
            }
        }
    }

    &__date-view {
        width: 100%;
    }
}

.react-datepicker {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    background-color: #FFF;
    color: #000;
    border: 0px;
    box-shadow: 0px 0px 10px #D1D1D1;
    border-radius: 10px;
    z-index: 3;
}

.react-datepicker__header {
    background-color: #FFF;
    border-bottom: 1px solid #E9E9E9;

}

.react-datepicker__today-button {
    background: #FFF;
    border-top: 1px solid #E9E9E9;

    @media screen and (max-width: 991px) {
        border-top: 0px
    }
}