.form-side-bar {
    border: 0px solid coral;
    padding-right: 10px;
    max-width: 250px;

    &__nav {
        list-style-type: none;

        &__item {
            margin-bottom: 5px;

            &__button {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                color: #505A62;
                padding: 7px 15px;
                width: 100%;
                text-align: left;
                border: 0px;
                background-color: #FFF;
                display: flex;
                border: 1px solid #cfcfcf;
                border-radius: 15px;
                white-space: nowrap;

                @media screen and (max-width: 991px) {
                    flex-direction: column;
                    text-align: center;
                    color: #555;
                    background-color: #F3F3F3;
                    padding: 7px 0px;
                    border: 0px;
                }

                &:hover {
                    background-color: #0095F6;
                    color: #FFFFFF;
                    border-radius: 15px;
                    border: 1px solid #0095F6;

                    @media screen and (max-width: 991px) {
                        background-color: #F3F3F3;
                        border: 0px;
                        border-bottom: 2px solid #0095F6;
                        border-radius: 0px;
                        color: #555;
                    }
                }

                &__icon {
                    font-size: 20px;
                    margin-right: 10px;

                    @media screen and (max-width: 991px) {
                        font-size: 16px;
                        margin-right: 0px;
                        width: 100%;
                        text-align: center;
                        display: none;
                    }
                }

                &--active {
                    background-color: #0095F6;
                    color: #FFF;
                    border-radius: 15px;
                    border: 1px solid #0095F6;

                    @media screen and (max-width: 991px) {
                        background-color: #F3F3F3;
                        border: 0px;
                        border-bottom: 2px solid #0095F6;
                        border-radius: 0px;
                        color: #555;
                    }
                }
            }

            &:last-child {
                @media screen and (max-width: 991px) {
                    padding-right: 15px;
                }
            }
        }

        @media screen and (max-width: 991px) {
            display: flex;
            gap: 20px;
            overflow: auto;
            height: 60px
        }
    }

    @media screen and (max-width: 991px) {
        display: flex;
        max-width: 100%;
        padding-right: 0px;
        border: 0px solid coral;
        height: 50px;
        overflow: hidden;
    }
}