.list-header {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &__title {
        flex: 1;
        border: 0px solid coral;

        @media screen and (max-width: 991px) {
            flex: 2;
        }
    }

    &__search {
        flex:2;
        min-width: 310px;
        border: 0px solid coral;

        @media screen and (max-width: 991px) {
            width: 100%;
            position: absolute;
            left: 0px;
            top: 35px;
            z-index: 1;
            padding: 10px 10px;
        }
    }

    &__tools {
        flex:3;
        display: flex;
        justify-content: right;
        gap: 8px;
        border: 0px solid coral;

        &__actions {
            display: flex;
            gap: 8px;
            justify-content: end;


            // @media screen and (max-width: 991px) {
            //     width: 100%;
            //     position: absolute;
            //     left: 0px;
            //     top: 50px;
            //     z-index: 1;
            //     padding: 0 14px;
            // }
        }

        @media screen and (max-width: 991px) {
            flex: 2;
        }
    }

    @media screen and (max-width: 991px) {
        position: relative;
        padding: 0px 10px !important;
        z-index: 2;
    }

    @media (min-width: 1200px) {
        max-width: 1870px !important;
    }
}