.home-nav-card {

    &__card {
        width: 100%;
        height: 240px;
        padding: 20px 20px;
        padding-bottom: 10px;
        background-color: #FFFFFF;
        border-radius: 10px;
        margin-top: 40px;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                font-family: "Open Sans", "Roboto", sans-serif;
                font-size: 16px;
                color: #1F272E;
                font-weight: 600;
            }

            &__icon-wrapper {
                border: 0px solid coral;
                background-color: #F9FAFA;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                display: flex;
                padding: 13px;
            }

            &__icon{
                font-size: 35px;
                color: #777;
            }
        }

        &__body {
            margin-top: 30px;
            display: flex;
            justify-content: center;

            &__currency {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                color: #1F272E;
                font-weight: 500;
            }

            &__value {
                font-family: "Roboto", sans-serif;
                font-size: 41px;
                font-weight: 400;
                color: #4A4A4A;
            }
        }
    }

    &--left {
        padding-right: 30px;

        @media screen and (max-width: 991px) {
            padding-right: 0px;
        }
    }

    &--center {
        padding-right: 15px;
        padding-left: 15px;

        @media screen and (max-width: 991px) {
            padding-right: 0px;
            padding-left: 0px;
        }
    }

    &--right {
        padding-left: 30px;

        @media screen and (max-width: 991px) {
            padding-left: 0px;
        }
    }
}