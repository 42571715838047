.form{
    border: 0px solid coral;
    position: relative;
    margin: 0px auto;

    &__nav-body{
        border: 0px solid coral;
        display: flex;

        &__nav{ 
            width: 18%;
            padding: 0px;
            padding-left: 15px;

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            @media screen and (min-width: 1300px) {
                width: 14%;
            }

            &--none{
                width: 0%;

                @media screen and (min-width: 1300px) {
                    width: 0%;
                }
            }
        }

        &__body{
            width: 82%;
            padding: 0px;
            padding-right: 15px;

        
            @media screen and (max-width: 991px) {
                width: 100%;
                padding: 0px 0px;
            }

            @media screen and (min-width: 1300px) {
                width: 86%;
            }

            &--full{
                width: 100%;

                @media screen and (min-width: 1300px) {
                    width: 100%;
                }
            }
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    @media screen and (min-width: 1200px) {
        max-width: 1870px !important;
    }
}