.report{
    position: relative;
    margin: 0px auto;

    &__nav-body{
        display: flex;

        &__nav{ 
            width: 16%;
            padding: 0px;
            padding-left: 15px;

            @media screen and (max-width: 991px) {
                width: 100%;
            }

            @media screen and (min-width: 1300px) {
                width: 14%;
            }
        }

        &__body{
            width: 84%;
            padding: 0px;
            padding-right: 15px;

            &--full{
                width: 100%;
            }

            @media screen and (max-width: 991px) {
                width: 100%;
                padding: 0px 0px;
            }

            @media screen and (min-width: 1300px) {
                width: 86%;
            }
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }

    @media screen and (min-width: 1200px) {
        max-width: 1870px !important;
    }
}