.report-body{
    display: flex;
    flex-direction: column;
    justify-content: right;
    position: relative;
    width: 100%;
  
    &__wrapper{
        width: 100%;
        padding: 0px 0px;
        border: 0px solid green;
        background-color: #FFFFFF;
        border-radius: 30px;
        border: 1px solid #EDEDED;
        
        @media screen and (max-width: 991px) {
            // min-height: calc(100vh - 280px);
            width: 100%;
            border-radius: 0px;
            height: calc(100vh - 175px);
        }
    }

    &__table{
        padding: 20px;

        @media screen and (max-width: 991px) {
            padding: 20px 0px;
        }
    }

    &__chart{
        width: 100%;
        padding: 25px 0px;
    }

    &__spinner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    @media screen and (max-width: 991px) {
        justify-content: center;
    }

}